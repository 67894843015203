import React, { useEffect, Children, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate } from 'react-router-dom'
import { subscribe } from '..//utils/pubsub'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'
import AddForm from './AddForm'
import Success from './Success'


type Props = {
}

function AddNewBusiness({
}: Props) {

  const {
    setupFlowFormFields,
    setSetupFlowFormFields,
    sendMessageToWebsocket,
    loginUrl,
    addFieldToSetupFlow
  } = useContext(GlobalContext)


  const [savingNewOrg, setSavingNewOrg] = useState(false)
  const [savingNewUser, setSavingNewUser] = useState(false)
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>({})
  const [formError, setFormError] = useState<string | null>(null)
  const [newOrganisationId, setNewOrganisationId] = useState<string | null>(null)
  const [newOwnerEmail, setNewOwnerEmail] = useState<string | null>(null)
  const navigate = useNavigate()

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key] = value
    setFormFields(newFieldValues)
  }

  const handleCheck = (event: any) => {
    console.log(event.target)
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    const isChecked = event.target.checked ? true : false
    const value = event.target.name
    let optionsArray = newFieldValues['ChecklistsToAdd'] || []
    if (isChecked === true && !optionsArray.includes(value)) {
      optionsArray.push(value)
    } else if (isChecked === false) {
      optionsArray = optionsArray.filter((option: string) => { return option !== value })
    }
    newFieldValues['ChecklistsToAdd'] = optionsArray
    setFormFields(newFieldValues)
  }

  const saveNewBusiness = async () => {
    setSavingNewOrg(true)
    const payload = JSON.stringify({
      action: "signup",
      values: formFields
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("saveSignupResponse", data => {
      //console.log(data)
      if (data.OrganisationId && data.UserEmail) {
        setNewOrganisationId(data.OrganisationId)
        setNewOwnerEmail(data.UserEmail)
        setSavingNewOrg(false)
      }
      unsubscribe()
    })
  }


  const validate = () => {
    let emailRegex = new RegExp("^.+@.+")
    if (
      formFields['OrganisationName'] && formFields['OrganisationName'].length > '2' &&
      formFields['UserEmail'] && emailRegex.test(formFields['UserEmail'])
    ) {
      saveNewBusiness()
    } else {
      setFormError('Please enter a business name and a valid email address')
    }
  }

  const updateUser = async (UserEmail: string | undefined | null, OrganisationId: string | undefined | null) => {
    if (UserEmail && OrganisationId) {
      setSavingNewUser(true)
      const payload = JSON.stringify({
        action: "signup",
        subAction: 'updateUser',
        values: {
          OrganisationId,
          UserEmail
        }
      })
      //console.log(payload)
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("updateUserResponse", data => {
        //console.log(data)
        if (data.OrganisationId && data.UserEmail) {
          //console.log(`updating org id ${data.OrganisationId} from ${data.OldEmail} to ${data.UserEmail}`)
          setNewOwnerEmail(data.UserEmail)
          setSavingNewUser(false)
        }
        unsubscribe()
      })
    }
    else {
      console.log('⛔️ error - no org id or email')
    }
  }




  return <div className={`px-4 py-6 w-full max-w-xl flex flex-col gap-8 items-center`}>

    {savingNewOrg && <Modal
      showCloseButton={true}
      setShowModal={setSavingNewOrg}
    ><Spinner><p>Setting up your organisation. This may take a couple of minutes.</p></Spinner>
    </Modal>}

    {savingNewUser && <Modal
      showCloseButton={true}
      setShowModal={setSavingNewOrg}
    ><Spinner><p>Updating user email...</p></Spinner>
    </Modal>}


    {newOrganisationId && newOwnerEmail ? <Success
      userEmail={newOwnerEmail}
      organisationName={formFields['OrganisationName']}
      newOrganisationId={newOrganisationId}
      updateUser={updateUser}
      setSavingNewUser={setSavingNewUser}
      savingNewUser={savingNewUser}
    />
      :
      <AddForm
        validate={validate}
        handleCheck={handleCheck}
        handleChange={handleChange}
        formFields={formFields}
        formError={formError}
      />
    }

  </div>
}
export default AddNewBusiness