import React, { useEffect, Children, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'


type Props = {
}

function SplashPage({
}: Props) {

    const {
        loginUrl,
    } = useContext(GlobalContext)


    return <div className={`w-screen h-screen bg-brandblue p-4 flex flex-col items-center justify-center`}>
        <div className={`w-64 flex flex-col gap-6 items-center justify-center text-white text-center mb-6`}>
            <img
                src={`/logos/logo-white-no-strapline.svg`}
                alt={'ChecQR logo'}
                className={``}
            />

            <p>Welcome to ChecQr!</p>
            <Button
                internalLinkUrl={`/new-account`}
                text={'Set up a new account'}
                variant={'secondary'}
                size={'big'}
            />
            <p className={`text-sm`}>Already have an account? <br /><a href={`https://dashboard.checqr.net/`} className={`underline`}>Go to your dashboard</a></p>


        </div>
    </div>
}
export default SplashPage