import React, { useEffect, Children, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate } from 'react-router-dom'
import { subscribe } from '..//utils/pubsub'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'


type Props = {
    handleChange: AnyFunction
    handleCheck: AnyFunction
    validate: AnyFunction
    formFields: ObjectStringKeyAnyValue
    formError: string | null
}

function AddForm({
    handleChange, handleCheck, validate, formFields, formError
}: Props) {

  const {
    setupFlowFormFields,
    setSetupFlowFormFields,
    sendMessageToWebsocket,
    loginUrl,
    addFieldToSetupFlow
  } = useContext(GlobalContext)





  return <div className={`w-full text-center flex flex-col gap-6`}>





    <h3 className={`font-righteous text-brandblue text-3xl`}>Set up a new ChecQR Account</h3>

    <div className={`animate-fadein w-full flex flex-col gap-2 items-center`}>
      <h3 className={`font-righteous text-brandblue text-lg`}>Business name:</h3>
      <input
        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
        type='text'
        name={`OrganisationName`}
        value={formFields['OrganisationName'] || ''}
        onChange={(e) => handleChange('OrganisationName', e.target.value)}

      />
    </div>

    <div className={`animate-fadein w-full flex flex-col gap-2 items-center`}>
      <h3 className={`font-righteous text-brandblue text-lg`}>User email address:</h3>
      <input
        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
        type='text'
        name={`UserEmail`}
        value={formFields['UserEmail'] || ''}
        onChange={(e) => handleChange('UserEmail', e.target.value)}

      />
    </div>


    {/* <div className={`animate-fadein w-full flex flex-col gap-2 items-center`}>

      <div className={`flex flex-row gap-1 items-center`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Include pre-set checklists:</h3>
        <p className={`text-sm italic text-gray-600`}>(optional)</p>
      </div>

      <div className={`flex md:flex-row md:gap-4 flex-col gap-1 items-center`}>
        <label className={`flex flex-row gap-2 items-center`}>
          <input
            type={`checkbox`}
            name={`OpeningChecks`}
            defaultChecked={formFields['ChecklistsToAdd'] && formFields['ChecklistsToAdd'].includes('OpeningChecks') ? true : false}
            onChange={handleCheck} />
          Opening Checks
        </label>
        <label className={`flex flex-row gap-2 items-center`}>
          <input
            type={`checkbox`}
            name={`ClosingChecks`}
            defaultChecked={formFields['ChecklistsToAdd'] && formFields['ChecklistsToAdd'].includes('ClosingChecks') ? true : false}
            onChange={handleCheck} />
          Closing Checks
        </label>
        <label className={`flex flex-row gap-2 items-center`}>
          <input
            type={`checkbox`}
            name={`DeliveryChecks`}
            defaultChecked={formFields['ChecklistsToAdd'] && formFields['ChecklistsToAdd'].includes('DeliveryChecks') ? true : false}
            onChange={handleCheck} />
          Delivery Checks
        </label>
      </div>
    </div> */}


    <div className={`animate-fadein w-full`}>
      <Button
        onClick={validate}
        variant={`primary`}
        size={`big`}
        text={`Add`}
      />
    </div>


    {formError && <RedAlert alignment='left'>{formError}</RedAlert>}

  </div>
}
export default AddForm