import React, { useState, useEffect, useRef } from 'react'
import { GlobalContext } from './GlobalContext'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { publish } from "./utils/pubsub"
import CloudformationOutputs from './CloudformationOutputs.json'
import Header from './ui-elements/Header'
import Footer from './ui-elements/Footer'
import AddNewBusiness from './components/AddNewBusiness'
import SplashPage from './components/SplashPage'


type ObjectStringKeyAnyValue = {
  [key: string]: any
}

function App() {

  const [loggedIn, setLoggedIn] = useState<boolean | string>(false)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [userData, setUserData] = useState<ObjectStringKeyAnyValue | null>(null)
  const [error, setError] = useState<string | null>(null)
  const loginUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/login?client_id=${CloudformationOutputs.UserPoolClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/dashboard/`
  const logoutUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/logout?client_id=${CloudformationOutputs.UserPoolClientId}&logout_uri=${window.location.origin}/`
  const WebSocketURI = CloudformationOutputs.WebSocketURI



  useEffect(() => {
    setConnectionState('tryToConnect')
  }, [])



  useEffect(() => {
    switch (connectionState) {
      case 'closed':
      case 'error':
        setConnectionState('waitThenReconnect')
        setTimeout(() => {
          setConnectionState('tryToConnect')
        }, 200)
        break;
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
        }
        break;

    }

  }, [connectionState])






  useEffect(() => {
    // handle incoming messages
    if (socket !== null) {
      actOnMessage()
    }
  }, [socket])




  const sendMessageToWebsocket = async (payload: string) => {
    if (socket) {
      //console.log(`sending to websocket: ${JSON.parse(payload).action}`)
      socket.send(payload)
    } else {
      console.log('🚫  Can not send to web socket')
    }
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        const action = JSON.parse(data).action
        const payload = JSON.parse(data).payload
        //console.log(`A message has been received: ${action} `)

        if (data) {
          publish(action, payload)

          switch (action) {
            case 'error':
              console.log(`🚫  Error:`)
              console.log(JSON.stringify(payload))
              const errorMessage = payload['details'] || 'An error has occurred'
              setError(errorMessage)
              break

            case 'saveSignupResponse':
            case 'addUserResponse':
            case 'updateUserResponse':
            case 'deleteUserResponse':
              // handled by pub sub
              break

            default:
              console.log(`A message has been received but the action is invalid. ${data}`)
              break
          }
        }
      }
    }
  }


  return <div className={`bg-gray-300 flex flex-col min-h-screen justify-between`}>

    <GlobalContext.Provider value={{
      sendMessageToWebsocket,
      loginUrl,
      error
    }}>
      <Router>


        <Routes>

          <Route
            path='/*'
            element={
              <SplashPage />
            } />

          <Route
            path='/new-account'
            element={<div className={`flex flex-col min-h-screen justify-between items-center justify-center`}>
              <div className={`w-full flex flex-col items-center justify-start`}>
                <Header
                  loggedIn={loggedIn}
                  logoutUrl={logoutUrl}
                  setLoggedIn={setLoggedIn}
                  setUserData={setUserData}
                  userData={userData}
                  currentOrganisation={null}
                  switchCurrentOrganisation={undefined}
                  isAccountSetUp={null}
                  sendMessageToWebsocket={sendMessageToWebsocket}
                  tableData={null}
                />
                <AddNewBusiness />
              </div>
              <Footer />
            </div>
            } />


        </Routes>
      </Router>
    </GlobalContext.Provider>

  </div>
}

export default App
