import React, { useEffect, Children, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate } from 'react-router-dom'
import { subscribe } from '..//utils/pubsub'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'


type Props = {
    userEmail?: string,
    organisationName?: string
    newOrganisationId?: string
    updateUser: AnyFunction
    savingNewUser: boolean
    setSavingNewUser: React.Dispatch<React.SetStateAction<boolean>>
}

function Success({
    userEmail,
    organisationName,
    newOrganisationId,
    updateUser,
    savingNewUser,
    setSavingNewUser
}: Props) {

    const {
        setupFlowFormFields,
        setSetupFlowFormFields,
        sendMessageToWebsocket,
        loginUrl,
        addFieldToSetupFlow
    } = useContext(GlobalContext)



    const [showModal, setShowModal] = useState(false)
    const [updatedEmail, setUpdatedEmail] = useState<string | null>(null)
    let emailRegex = new RegExp("^.+@.+")


    return <div className={`flex flex-col gap-4 text-center`}>

        {showModal && <Modal>
            <div className={`flex flex-col gap-4 text-center`}>

                <h3 className={`font-righteous text-brandblue text-3xl`}>Edit owner email address</h3>
                <p>New email:</p>

                <input
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    type='text'
                    name={`OrganisationName`}
                    value={updatedEmail || ''}
                    onChange={(e) => setUpdatedEmail(e.target.value)}
                />

                <div className={`flex flex-row gap-2`}>
                    <Button
                        onClick={() => setShowModal(false)}
                        variant={`gray`}
                        size={`big`}
                        text={`Cancel`}
                    />
                    <Button
                        onClick={() => {
                            setShowModal(false)
                            updateUser(updatedEmail, newOrganisationId)
                        }}
                        variant={updatedEmail && emailRegex.test(updatedEmail) ? `primary` : 'gray'}
                        disabled={updatedEmail && emailRegex.test(updatedEmail) ? false : true}
                        size={`big`}
                        text={`Update`}
                    />
                </div>
            </div>
        </Modal>}



        <h3 className={`font-righteous text-brandblue text-3xl`}>Success!</h3>
        <p>Your account has been successfully created</p>

        <div className={`flex flex-row gap-2 items-center`}>
            {organisationName && <p className={`font-bold`}>Business Name:</p>}
            {organisationName && <p className={``}>{organisationName}</p>}
            <p className={`italic text-gray-600 text-xs`}>(ID #:{newOrganisationId})</p>
        </div>

        <div className={`flex flex-row gap-2 items-center`}>
            <p className={`font-bold`}>Owner Email:</p>
            <p className={``}>{userEmail}</p>
            <p onClick={() => setShowModal(true)} className={`underline cursor-pointer`}>Edit</p>
        </div>



        <div className={`animate-fadein w-full`}>
            <Button
                externalLinkUrl={`https://dashboard.checqr.net/`}
                variant={`primary`}
                size={`big`}
                text={`Log in to your dashboard`}
            />
        </div>



    </div>
}
export default Success