import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import XButton from '../formfields/XButton'

type Props = {
  children: any
  setShowModal?: any
  showCloseButton?: boolean
  closeButtonValue?: any
}

function Modal({
  children,
  setShowModal,
  showCloseButton,
  closeButtonValue
}: Props) {

  return <div className={`fixed z-50 top-0 left-0 min-h-full w-full bg-black bg-opacity-60 flex items-center justify-center p-6`}>
    <div
      className="rounded hover:opacity-90-xl w-full max-w-lg p-6 bg-white divide-y divide-gray-500 shadow-2xl overflow-y-scroll relative"
      style={{
        maxHeight: '80vh'
      }}
    >
      {/* {setShowModal && showCloseButton && <div className={`absolute right-0 top-0 border-none mb-3 cursor-pointer bg-gray-500   m-2 w-6 h-6 font-white flex items-center justify-center`} style={{
        borderRadius: '50%',
        padding: '2px'
      }}
        onClick={() => setShowModal(closeButtonValue || false)}
      >

        <img className={`w-3 h-3`} src='/close-x.svg' />
      </div>} */}

      {setShowModal && showCloseButton && <div className={`absolute right-0 top-0 m-1`}>
        <XButton clickFunction={() => {
        setShowModal(closeButtonValue || false)
      }} />
      </div>}

      <div className={`border-none`}>
        {children}
      </div>
    </div>
  </div>
}
export default Modal